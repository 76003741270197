<template>
  <ion-page id="re-page">
    <ion-content :fullscreen="true">
      <div class="pc-box">
        <div class="pc-min-content">
          <div>
            <div class="statusBar" ref="statusBar"></div>
            <div class="me-bar" :class="{ userHeader: $store.state.form.token }" ref="topMenu">
              <div class="bar-title" v-if="!$store.state.form.token">My Account</div>
              <div v-if="$store.state.form.token" class="usericon main-color">
                <img :src="$store.state.form.userInfo.icon ? $store.state.form.userInfo.icon : defaultIcon" />
              </div>
              <div class="userNameItem" v-if="$store.state.form.token">
                <span class="name">{{ $store.state.form.userInfo.userName }}</span>
                <img class="edit pointer" src="../assets/edit.png" @click="getEditProfile" />
              </div>
            </div>
          </div>
          <img style="padding-top: 30px; width: 100%" src="../assets/login.jpg" v-if="!$store.state.form.token" />
          <div class="me-content">
            <div v-if="!$store.state.form.token">
              <div class="main-color font-m font-16">Register or Log In to receive great offers, member only specials, and the opportunity to support your favourite club school or charity.</div>
              <!-- <div class="main-color font-16 loginTip">
            Don’t have an account?
            <u @click="goRegistration">Sign up</u>
          </div> -->
              <ion-button class="login-btn" @click="goRegistration">Register</ion-button>
              <ion-button class="login-btn" @click="goLogin">Log in</ion-button>
            </div>
            <div v-else>
              <div class="center-item" @click="getTransHistory">
                <div class="icon-image">
                  <img src="../assets/transaction.png" />
                </div>
                <div class="label font-18 main-color Bold">Transaction History 11</div>
              </div>
              <div class="center-item" @click="goRewardsHistory">
                <div class="icon-image">
                  <img src="../assets/reward.png" />
                </div>
                <div class="label font-18 main-color Bold">Rewards History</div>
              </div>
              <div class="center-item" @click="geteStampHistory">
                <div class="icon-image">
                  <img src="../assets/estamp.png" />
                </div>
                <div class="label font-18 main-color Bold">eStamp History</div>
              </div>
              <div class="center-item" @click="geteCouponHistory">
                <div class="icon-image">
                  <img src="../assets/coupons.png" />
                </div>
                <div class="label font-18 main-color Bold">eCoupon History</div>
              </div>
              <div class="center-item" @click="getCollectorHistory">
                <div class="icon-image">
                  <img src="../assets/collectorcard.png" />
                </div>
                <div class="label font-18 main-color Bold">Collector Card History</div>
              </div>
              <div class="center-item" @click="getReferral">
                <div class="icon-image">
                  <img src="../assets/friends.png" />
                </div>
                <div class="label font-18 main-color Bold">Referral</div>
              </div>
              <div class="center-item no-border" @click="logoutEvent">
                <div class="icon-image">
                  <img src="../assets/logout.png" />
                </div>
                <div class="label font-18 main-color Bold">Logout</div>
              </div>
            </div>
            <ion-button class="login-btn" @click="getTest">app test</ion-button>
          </div>
          <wallet-btn />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import walletBtn from "@/components/walletBtn.vue";
import config from "../config";
// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

export default {
  name: "meAndMore",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, IonButton, walletBtn },
  data() {
    return {};
  },
  methods: {
    goRegistration() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 1;
      this.$router.push({
        path: "/signup",
      });
    },
    goLogin() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 2;
      this.$router.push({
        path: "/login",
      });
    },
    goRewardsHistory() {
      this.$router.push({
        path: "/rewardsHistory",
      });
    },
    getTest() {
      this.$router.push({
        path: "/test",
      });
    },
    getEditProfile() {
      this.$router.push({
        path: "/editProfile",
      });
    },
    getTransHistory() {
      this.$router.push({
        path: "/TransHistory",
      });
    },
    geteStampHistory() {
      this.$router.push({
        path: "/eStampHistory",
      });
    },
    getCollectorHistory() {
      this.$router.push({
        path: "/collectorHistory",
      });
    },
    geteCouponHistory() {
      this.$router.push({
        path: "/eCouponHistory",
      });
    },
    getReferral() {
      this.$router.push({
        path: "/referral",
      });
    },
    async logoutEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.logout;
      await this.$store.dispatch("apiEvent", objdata);
      this.$store.state.form.token = "";
      this.setStorageEvent();
      this.$router.push({
        path: "/main/landing",
      });
    },
    async FacebookLogin() {
      // const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos', 'user_gender'];
      // const result = await FacebookLogin.login({
      //   permissions: FACEBOOK_PERMISSIONS
      // });
      // console.log(JSON.stringify(result));
      // const token = await FacebookLogin.getCurrentAccessToken();
      // console.log(JSON.stringify(token));
    },
  },
  ionViewWillEnter() {
    this.clickTabEvent("meAndMore");
    this.$store.state.exitApp = false;
  },
};
</script>
